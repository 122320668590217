<template>
  <b-card class="col-12 col-lg-8 c">
    <div v-if="loading" class="g text-center">
      <h4>
        <img src="../../assets/images/loading.svg" alt="" />
        جاري الحميل...
      </h4>
    </div>
    <div class="col-12">
        <div class="col-12 col-lg-6 c g text-center">
          <div class="form-group" v-if="!selected_group">
            <label for="">فرز بمجموعة الإعدادات</label>
            <select
              class="form-control"
              name=""
              v-model="selected_group"
              @change="ga()"
              id=""
            >
                <option :value="null">-- اختر --</option>
              <template v-for="group in groups">
                <option :value="group.code" :key="group.code">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-12 col-lg-6 g">
            <div class="form-group">
          <label for="">رقع تقرير تاريخ</label>
          <input type="date"
            class="form-control" v-model="date">
        </div>
          </div>
          <div class="col-12 col-lg-6 g">
            <div class="form-group">
        <label for="">تغيير الكل إلى:</label>
        <select class="form-control" @change="allTo()" v-model="all">
          <option :value="1">حاضر</option>
          <option :value="2">متأخر</option>
          <option :value="3">غائب</option>
          <option :value="4">انصراف</option>
        </select>
      </div>
          </div>
        </div>
    </div>
    <div class="col-12 table-responsive">
      <table class="table table-hover table-bordered">
        <thead>
          <th>المعلم</th>
          <th>الحالة</th>
        </thead>
        <tbody>
          <tr
            v-for="teacher in teachers"
            :key="teacher._id"
            @change="refreshReports()"
            :class="
              reports[teacher.number].new == 3
                ? 'bg-light-danger'
                : reports[teacher.number].new == 1
                ? 'bg-light-success'
                : reports[teacher.number].new == 2
                ? 'bg-light-warning'
                : reports[teacher.number].new == 4
                ? 'bg-light-primary'
                : ''
            "
          >
            <td>
              {{ teacher.name }}
            </td>
            <td>
              <select
                style="width: 100%; border: 2px solid #999"
                v-model="reports[teacher.number].new"
              >
                <option :value="1">حاضر</option>
                <option :value="2">متأخر</option>
                <option :value="3">غائب</option>
                <option :value="4">انصراف</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-12 text-center g">
      <button
        :class="loading ? 'disabled btn' : 'btn btn-success'"
        @click="upload()"
      >
        <span v-if="loading">
          <img
            src="../../assets/images/loading.svg"
            style="width: 24px"
            alt=""
          />
          جاري الرفع...</span
        >
        <span v-if="!loading">حفظ التقرير الآن</span>
      </button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {
      groups: [],
      teachers: [],
      reports: {},
      viewed: [],
      all: undefined,
      loading: true,
      selected_group: null,
      date: new Date().toISOString().split("T")[0]
    };
  },
  methods: {
    ga(){
      var arr = [], selected = this.selected_group
      this.teachers.forEach(function(a){
        if(a.settings_group == selected){
          arr.push(a)
        }
      })
      this.teachers = arr
    },
    upload() {
      var g = this,
        _g = this;
      this.loading = true;
      var arr = [];
      for (const property in this.reports) {
        arr.push({
          number: property,
          old: g.reports[property].old,
          status: g.reports[property].new,
        });
      }
      axios
        .post(localStorage.getItem("api") + "/teachers/upload-bulk", {
          jwt: localStorage.getItem("jwt"),
          date: g.date,
          arr: arr,
        })
        .then(function (r) {
          if (r.data.status == 100) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم الرفع بنجاح!",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            _g.loading = false;
          } else {
            _g.loading = false;
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ اثناء الرفع 3",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(function (e) {
          console.log(e);
          _g.loading = false;
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ اثناء الرفع",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        });
    },
    refreshReports() {
      this.reports = JSON.parse(JSON.stringify(this.reports));
    },
    allTo() {
      var arr = {},
        g = this,
        n = this.all;
      for (const property in this.reports) {
        arr[property] = {
          old: g.reports[property].old,
          new: n,
        };
      }
      this.reports = arr;
      this.refreshReports();
    },
  },
  created() {
    var _g = this,
      g = _g;
    var _g = this;
    axios
      .post(localStorage.getItem("api") + "/teachers/groups", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.groups = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    axios
      .post(localStorage.getItem("api") + "/reports/index", {
        jwt: localStorage.getItem("jwt"),
        date: new Date().toISOString().split("T")[0],
      })
      .then(function (r) {
        if (r.data.status == 100) {
          r.data.response.forEach(function (a) {
            if (a.status != 4 && !g.viewed.includes(a.number)) {
              g.viewed.push(a.number);
              _g.reports[a.number] = {};
              _g.reports[a.number].old = a.status;
              _g.reports[a.number].new = a.status;
            }
          });
          axios
            .post(localStorage.getItem("api") + "/teachers/list", {
              jwt: localStorage.getItem("jwt"),
            })
            .then(function (r) {
              if (r.data.status == 200) {
                _g.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "حدث خطأ JWT",
                    icon: "WarningIcon",
                    variant: "danger",
                  },
                });
              } else {
                _g.teachers = r.data.response;
                _g.loading = false;
              }
            })
            .catch(function () {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            });
        } else {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ 2",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        }
      })
      .catch(function () {
        g.loading = false;
        g.table = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>
